// @ts-nocheck

'use strict';

/** @param {string} apiKey */
function bootstrapGoogleMaps(apiKey) {
    (g=>{var h,a,k,p='The Google Maps JavaScript API',c='google',l='importLibrary',q='__ib__',m=document,b=window;b=b[c]||(b[c]={});var d=b.maps||(b.maps={}),r=new Set,e=new URLSearchParams,u=()=>h||(h=new Promise(async(f,n)=>{await (a=m.createElement('script'));e.set('libraries',[...r]+'');for(k in g)e.set(k.replace(/[A-Z]/g,t=>'_'+t[0].toLowerCase()),g[k]);e.set('callback',c+'.maps.'+q);a.src=`https://maps.${c}apis.com/maps/api/js?`+e;d[q]=f;a.onerror=()=>h=n(Error(p+' could not load.'));a.nonce=m.querySelector('script[nonce]')?.nonce||'';m.head.append(a)}));d[l]?console.warn(p+' only loads once. Ignoring:',g):d[l]=(f,...n)=>r.add(f)&&u().then(()=>d[l](f,...n))})({
        key: apiKey,
        v: 'weekly',
    });
}

async function initGoogleMap() {
    const dataMetaElem = document.getElementById('js-googleData');
    if (dataMetaElem === null) {
        throw new Error('expected to find element with id js-googleData');
    }

    bootstrapGoogleMaps(dataMetaElem.dataset.apiKey);

    const dealerContactElem = document.getElementById('js-dealer-contact');
    if (dealerContactElem === null) {
        throw new Error('expected to find element with id js-dealer-contact');
    }

    const googleJsonElem = document.getElementById('js-google-json');
    if (googleJsonElem === null) {
        throw new Error('expected to find element with id js-google-json');
    }

    const googleMapsData = JSON.parse(googleJsonElem.textContent);
    const dealerContact = JSON.parse(dealerContactElem.textContent);

    try {
        var { event } = await google.maps.importLibrary('core');
    } catch (e) {
        // google maps won't load so we bail
        console.error(e);
        return;
    }
    const { Map, InfoWindow } = await google.maps.importLibrary('maps');

    const map = new Map(document.getElementById('googlemaps_map'), {
        center: {lat: dealerContact.address.latitude, lng: dealerContact.address.longitude},
        zoom: 11,
        streetViewControl: false,
        scrollwheel: false,
        minZoom: 6,
        styles: JSON.parse(googleMapsData.styles),
    });

    var contentHtml =
        '<strong>' + dealerContact.name + '</strong>' +
        '<div class="hidden-xs">' +
            dealerContact.address.street + '<br>' +
            dealerContact.address.postCode + ' ' + dealerContact.address.city + '<br>' +
            '<b>' + dealerContact.phoneNo + '</b>' +
        '</div>';
    const infoWindow = new InfoWindow({content: contentHtml});

    // One object marker per one entity
    const image = googleMapsData.srcIconTeamshop89;

    const { Marker } = await await google.maps.importLibrary('marker');

    const objectMarker = new Marker({
        position: {lat: dealerContact.address.latitude, lng: dealerContact.address.longitude},
        map: map,
        icon: image,
        zIndex: 3,
        title: dealerContact.name,
        address: dealerContact.address.street,
        url: dealerContact.eMail,
        list_id: 0,
    });

    event.addListener(objectMarker, 'click', () => infoWindow.open(map, objectMarker), objectMarker);

    loadGoogleStreetviewAsync();
}

async function loadGoogleStreetviewAsync() {
    const views = document.querySelectorAll('.gsvContent__view');
    if (views.length === 0) {
        return;
    }
    for (const view of views) {
        if (! (view instanceof HTMLElement)) {
            continue;
        }
        let viewWrapper = view.closest('.gsvContent');
        if (! (viewWrapper instanceof HTMLElement)) {
            continue;
        }
        if (viewWrapper instanceof HTMLElement && Boolean(viewWrapper.dataset.disabled)) {
            continue;
        }
        let stringOptions = view.dataset.options;
        let interval;
        let button = viewWrapper.querySelector('.gsvContent__button');

        let defaultOptions = {
            rotation: 0.1,
            interval: 20,
            lat: 0,
            lng: 0,
            heading: 0,
            pitch: 0,
        };

        let options = {};

        if (stringOptions) {
            let optionsSplit = stringOptions.split(';');

            for (const option of optionsSplit) {
                const target = {};
                const split = option.split('=');
                const key = split[0].charAt(0).toLowerCase() + split[0].slice(1).split(' ').join('');
                target[key] = option.split('=')[1];
                Object.assign(options, target);
            }
        }

        options = {...defaultOptions, ...options};

        const coordinates = {lat: parseFloat(options.lat), lng: parseFloat(options.lng)};

        const { StreetViewService, StreetViewPanorama } = await google.maps.importLibrary('streetView');
        const sv = new StreetViewService();

        try {
            sv.getPanorama({
                location: coordinates,
                radius: 350,
            }, (data, status) => {
                if (status != 'OK') {
                    return;
                }
                const panorama = new StreetViewPanorama(
                    view,
                    {
                        position: coordinates,
                        scrollwheel: false,
                        pov: {
                            heading: Number.parseFloat(options.heading),
                            pitch: Number.parseFloat(options.pitch),
                        },
                    },
                );

                if (Number.parseFloat(options.rotation) > 0) {
                    interval = setInterval(() => {
                        const oldPov = panorama.getPov();
                        if (typeof oldPov !== 'undefined') {
                            panorama.setPov({
                                heading: oldPov.heading + Number.parseFloat(options.rotation),
                                pitch: oldPov.pitch,
                            });
                        }
                    }, Number.parseInt(options.interval));
                }

                viewWrapper.classList.add('active');
            });
        } catch (e) {
            console.error(e);
            return;
        }

        if (button) {
            button.addEventListener('click', () => {
                const targetOverlay = button.closest('.gsvContent__overlay');
                if (targetOverlay === null) {
                    return;
                }
                targetOverlay.remove();
                clearInterval(interval);
            });
        }
    }
}

initGoogleMap();
